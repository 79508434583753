<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/digitalService/banner.jpg" alt="" />
    </div>
    <div class="container" id="zffw">
      <div class="box box_padding">
        <div class="title">支付服务</div>
        <div class="card_list">
          <div class="card_item" @click="toPosDetail">
            <div class="card_img">
              <img src="../../assets/img/digitalService/zffw1.png" alt="" />
            </div>
            <div class="card_main">
              <div class="card_title">POS收款</div>
              <div class="card_content">可提供刷卡、插卡、挥卡、扫码服务</div>
            </div>
          </div>
          <div class="card_item" @click="toJhmDetail">
            <div class="card_img">
              <img src="../../assets/img/digitalService/zffw2.png" alt="" />
            </div>
            <div class="card_main">
              <div class="card_title">聚合码收款</div>
              <div class="card_content">可摆放、可张贴</div>
            </div>
          </div>
          <div class="card_item" @click="toSfkDetail">
            <div class="card_img">
              <img src="../../assets/img/digitalService/zffw3.png" alt="" />
            </div>
            <div class="card_main">
              <div class="card_title">批量收款/付款</div>
              <div class="card_content">可提供刷卡、插卡、挥卡、扫码服务</div>
            </div>
          </div>
          <div class="card_item" @click="toDsdfDetail">
            <div class="card_img">
              <img src="../../assets/img/digitalService/zffw4.png" alt="" />
            </div>
            <div class="card_main">
              <div class="card_title">代收/代付</div>
              <div class="card_content">可提供刷卡、插卡、挥卡、扫码服务</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container" id="qyjy">
      <div class="box box_padding">
        <div class="title">企业经营</div>
        <div class="card_list">
          <div class="card_item" @click="toMhDetail">
            <div class="card_img">
              <img src="../../assets/img/digitalService/qyjy1.png" alt="" />
            </div>
            <div class="card_main">
              <div class="card_title">营销推广</div>
              <div class="card_content">多渠道多场景、客户一号触达、优化销售场景、提高商机转化</div>
            </div>
          </div>
          <div class="card_item" @click="toMcDetail">
            <div class="card_img">
              <img src="../../assets/img/digitalService/qyjy2.png" alt="" />
            </div>
            <div class="card_main">
              <div class="card_title">凭证归集</div>
              <div class="card_content">标准化凭证一键归集，让您记账更便捷</div>
            </div>
          </div>
          <div class="card_item" @click="toSyjDetail">
            <div class="card_img">
              <img src="../../assets/img/digitalService/qyjy3.png" alt="" />
            </div>
            <div class="card_main">
              <div class="card_title">收银机</div>
              <div class="card_content">满足各类餐饮、零售、甜品店铺实际销售场景使用需求，支持会员、外卖等场外销售建设</div>
            </div>
          </div>
          <div class="card_item" @click="toDdxcxDetail">
            <div class="card_img">
              <img src="../../assets/img/digitalService/qyjy4.png" alt="" />
            </div>
            <div class="card_main">
              <div class="card_title">点单小程序</div>
              <div class="card_content">满足餐饮、零售、甜品店铺等具有自助消费场景的需求，客户自助点单，省时省力，支持商家私域流量池搭建</div>
            </div>
          </div>
          <div class="card_item" @click="toYsDetail">
            <div class="card_img">
              <img src="../../assets/img/digitalService/qyjy5.png" alt="" />
            </div>
            <div class="card_main">
              <div class="card_title">三/四要素验证</div>
              <div class="card_content">官方权威核查，实时校验，登录场景身份验证小帮手</div>
            </div>
          </div>
          <div class="card_item" @click="toGsxxyzDetail">
            <div class="card_img">
              <img src="../../assets/img/digitalService/qyjy6.png" alt="" />
            </div>
            <div class="card_main">
              <div class="card_title">工商信息验证</div>
              <div class="card_content">企业工商信息实时查询，联动官方机构，可应用：电商平台的店铺入驻，小微企业贷款、商户入网信息审核</div>
            </div>
          </div>
          <i></i>
          <i></i>
          <i></i>
        </div>
      </div>
    </div>

    <div class="container" id="cjcp">
      <div class="box">
        <div class="title">场景服务</div>
        <div class="card_list">
          <div class="card_item" @click="toQytcDetail">
            <div class="card_img">
              <img src="../../assets/img/digitalService/qyjy2-1.png" alt="" />
            </div>
            <div class="card_main">
              <div class="card_title">企业团餐</div>
              <div class="card_content">满足企业食堂等使用场景支持，刷脸支付、餐补政策、员工考勤等多种服务能力</div>
            </div>
          </div>
          <div class="card_item" @click="toK12Detail">
            <div class="card_img">
              <img src="../../assets/img/digitalService/qyjy2-2.png" alt="" />
            </div>
            <div class="card_main">
              <div class="card_title">K12</div>
              <div class="card_content">面向校园、教育行业服务，家长实时了解学生在校消费动向，学生免去带现金进校园问题</div>
            </div>
          </div>
          <i></i>
          <i></i>
          <i></i>
        </div>
      </div>
    </div>
    <div class="container" id="hlht">
      <div class="box">
        <div class="title">互联互通</div>
        <div class="ht_list">
          <div class="ht_item">
            <img src="../../assets/img/digitalService/ht1.png" alt="">
          </div>
          <div class="ht_item">
            <img src="../../assets/img/digitalService/ht2.png" alt="">
          </div>
          <div class="ht_item">
            <img src="../../assets/img/digitalService/ht3.png" alt="">
          </div>
          <div class="ht_item">
            <img src="../../assets/img/digitalService/ht4.png" alt="">
          </div>
          <div class="ht_item">
            <img src="../../assets/img/digitalService/ht5.png" alt="">
          </div>
          <div class="ht_item">
            <img src="../../assets/img/digitalService/ht6.png" alt="">
          </div>
          <div class="ht_item">
            <img src="../../assets/img/digitalService/ht7.png" alt="">
          </div>
          <div class="ht_item">
            <img src="../../assets/img/digitalService/ht8.png" alt="">
          </div>
          <i></i>
          <i></i>
          <i></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    toPosDetail() {
      this.$router.push({
        path: "/digitalService/detail_pos",
        // query: { from: "pos" },
      });
    },
    // 聚合码
    toJhmDetail() {
      this.$router.push({
        path: "/digitalService/detail_jhm",
        // query: { from: "jhm" },
      });
    },
    // 批量收付款
    toSfkDetail() {
      this.$router.push({
        path: "/digitalService/detail_sfk",
        // query: { from: "sfk" },
      });
    },
    // 代收代付
    toDsdfDetail() {
      this.$router.push({
        path: "/digitalService/detail_dsdf",
        // query: { from: "sfk" },
      });
    },
    // 收银机
    toSyjDetail() {
      this.$router.push({
        path: "/digitalService/detail_syj",
        // query: { from: "sfk" },
      });
    },
    // 点单小程序
    toDdxcxDetail() {
      this.$router.push({
        path: "/digitalService/detail_ddxcx",
        // query: { from: "sfk" },
      });
    },
    // 企业团餐
    toQytcDetail() {
      this.$router.push({
        path: "/digitalService/detail_qytc",
        // query: { from: "sfk" },
      });
    },
    // k12
    toK12Detail() {
      this.$router.push({
        path: "/digitalService/detail_k12",
        // query: { from: "sfk" },
      });
    },
    // 密函
    toMhDetail() {
      this.$router.push({
        // path: "/digitalService/detail_yhmh",
        // query: { from: "sfk" },
        path: "/market",
        query: { org: "digitalService" },
      });
    },
    // 密巢
    toMcDetail() {
      this.$router.push({
        // path: "/digitalService/detail_yhmc",
        path: "/certificateCollection",
        // query: { from: "sfk" },
      });
    },
    // 要素认证
    toYsDetail() {
      this.$router.push({
        path: "/digitalService/detail_ysyz",
        // query: { from: "sfk" },
      });
    },
    // 工商信息验证
    toGsxxyzDetail() {
      this.$router.push({
        path: "/digitalService/detail_gsxxyz",
        // query: { from: "sfk" },
      });
    },
    toDetail() {
      this.$router.push({
        path: "/digitalService/detail",
        query: { id: Math.floor(Math.random() * 100) + 1 },
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.wrapper {
  background: #f5f5f5;
}
.banner {
  width: 100%;
  height: 566px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
}

.box {
  width: 1200px;
  margin: 0 auto;
  padding: 20px 0 0;
}

.box_padding {
  padding-top: 60px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.card_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
}

.card_list i {
  width: 20%;
  margin: 20px;
}

.card_item {
  width: 20%;
  padding: 20px;
  border-radius: 10px;
  margin: 20px;
  background: #fff;
}

.card_item:hover {
  box-shadow: 0 0 8px 0 rgba(187, 193, 210, 0.6),
    0 2px 4px 0 rgba(232, 237, 250, 0.5);
}

.card_img {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.card_img img {
  display: block;
  width: 100%;
  height: 100%;
}

.card_main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card_title {
  font-size: 14px;
  color: #333;
}

.card_content {
  color: #999;
  font-size: 12px;
  margin-top: 10px;
}

.ht_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
}

.ht_item {
  width: 23%;
  height: 85px;
  border-radius: 10px;
  background: #FFF;
  overflow: hidden;
  padding: 20px 0;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ht_item img {
  display: block;
}

.ht_list i {
  width: 25%;
}
</style>
